import SvgPassages from '../../assets/svg/passages.svg'
import CardSearchAndAdd from '../../components/cardSearchAndAdd';
import StaffPage from '../../components/staffPage';
import TableComponent from '../../components/tableComponent';
import { FiTrash2, FiEdit } from "react-icons/fi";
import EditPassages from './edit_passages';
import DeletePassages from './delete_passages';
import nullAbleImg from '../../assets/nuable_img.png'
import { useNavigate } from 'react-router-dom';
import HPassages from '../../hooks/passages';
import { useEffect, useState } from 'react';
import moment from 'moment';


export default function Passages() {
  const token = localStorage.getItem('T');
  const navigate = useNavigate();
  const apiPassages = HPassages();
  const [listPassages, setListPassages] = useState<any>();
  const [firstDataPassage, setFirstDataPassage] = useState<any>([]);
  const [firstListAllPassStatus, setfirstListAllPassStatus] = useState<any>([]);
  const [loading, setloading] = useState<boolean>(false);

  const [elapsedTimes, setElapsedTimes] = useState<{ [key: string]: string }>({});


  const [search, setSearch] = useState<string>('');
  const [perPage, setPerPage] = useState<any>(10);



  const getListPassages = async (numPage = 1, _search = search, _perPage = perPage) => {
    setloading(true)
    const params = {
      page: numPage,
      search: _search,
      perPage: _perPage
    }
    await apiPassages.index(params).then((res: any) => {
      setListPassages(res)
    }).finally(() => { setloading(false) })
  }

  const statusColors: any = {
    1: '#ff9702',
    2: '#006bff',
    3: '#ff5602',
    4: '#009b60',
    5: '#ea2200',
    6: '#ff00a5',
    default: '#233446'
  };

  const perPageOnChange = (e: any) => {
    const newPerPage = e.target.value;
    setPerPage(newPerPage);
    getListPassages(1, search, newPerPage);
  }

  const getAllPassStatus = async () => {

    await apiPassages.listPassStatus().then((res) => {
      setfirstListAllPassStatus(res)
    })

  }


  useEffect(() => {
    getListPassages();
    getAllPassStatus();
  }, [])

  // Atualize o tempo decorrido a cada segundo
  useEffect(() => {
    if (listPassages) {
      const intervals: { [key: string]: NodeJS.Timeout } = {};

      listPassages.data.forEach((d: any) => {
        if (!d.entry_time) return;
        const startTime = moment(d.entry_time);
        const endTime = d.exit_time ? moment(d.exit_time) : null;

        intervals[d.id] = setInterval(() => {
          const now = endTime ? endTime : moment();
          const duration = moment.duration(now.diff(startTime));

          const hours = Math.floor(duration.asHours());
          const mins = Math.floor(duration.asMinutes()) - hours * 60;
          const secs = Math.floor(duration.asSeconds()) - mins * 60 - hours * 3600;

          const formattedHours = hours.toString().padStart(2, '0');
          const formattedMins = mins.toString().padStart(2, '0');
          const formattedSecs = secs.toString().padStart(2, '0');

          setElapsedTimes(prev => ({ ...prev, [d.id]: `${formattedHours}:${formattedMins}:${formattedSecs} ` }));

          // Se existir um horário de saída, limpe o intervalo
          if (endTime) clearInterval(intervals[d.id]);
        }, 1000);
      });



      return () => Object.values(intervals).forEach(clearInterval);
    }
  }, [listPassages]);

  return (
    <>
      <StaffPage iconSvg={SvgPassages} description='Registre todas as passagens para manter um controle detalhado!' name='Passagens' />

      <CardSearchAndAdd add={() => { navigate(`store`) }} searchButton={() => { getListPassages(1, search, perPage) }} searchValue={search} searchChange={(e: any) => { setSearch(e.target.value) }} />

      <div className="card">
        <h5 className="card-header">Todas Passagens</h5>
        <TableComponent size='sm' loading={loading} perPageOnChange={perPageOnChange} perPageValue={perPage} PaginateFunction={getListPassages} PaginateListData={listPassages?.meta} colums={['#', 'Foto', 'Nome Completo', 'status', 'Chegada', 'Entrada', 'Tempo', 'Saída', 'Tipo', 'Documento', 'Ações',]} >
          {
            listPassages &&
            listPassages.data.map((d: any, k: any) => {
              console.log(d.exit_time)
              return (
                <tr key={k}>
                  <td>{d.id}</td>

                  <td>
                    <span title={d.name} style={{ cursor: 'pointer' }} onClick={() => { (window as any).$('#viewImage').modal('show') }} >
                      {
                        d.registerCommon.photo_path ?
                          <img width={32} height={32} src={d.registerCommon.image_url + '/' + token} className="rounded-circle" />
                          : <img width={32} height={32} src={nullAbleImg} />
                      }
                    </span>
                  </td>

                  <td>{d.registerCommon.name}</td>

                  <td>
                    <span style={{ backgroundColor: statusColors[d.pass_status_id] || statusColors.default }} className='badge'>
                      {d.pass_status}
                    </span>
                  </td>

                  <td>{moment(d.created_at).format('DD/MM/YYYY HH:mm')}</td>
                  <td>{d.entry_time ? moment(d.entry_time).format('DD/MM/YYYY HH:mm') : '---'}</td>
                  <td>{elapsedTimes[d.id] || '---'}</td>
                  <td>{d.exit_time ? moment(d.exit_time).format('DD/MM/YYYY HH:mm') : '---'}</td>
                  <td>{d.registerCommon.types_people}</td>
                  <td>{d.registerCommon.document}</td>

                  <td>
                    <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                      <button onClick={() => { (window as any).$('#modalEdit').modal('show'); setFirstDataPassage(d) }} title='Editar' type="button" className="btn btn-primary"><FiEdit /></button>
                      <button onClick={() => { (window as any).$('#modalDelete').modal('show'); setFirstDataPassage(d) }} disabled={d.pass_status_id != 1 ? true : false} title='Depois de alterar o status desta passagem, a exclusão da mesma não será mais possível.' type="button" className="btn btn-primary"><FiTrash2 /></button>
                    </div>
                  </td>
                </tr>
              )
            })
          }

        </TableComponent>
      </div>

      <EditPassages getListPassages={getListPassages} firstListAllPassStatus={firstListAllPassStatus} firstDataPassage={firstDataPassage} token={token} id='modalEdit' />
      <DeletePassages getListPassages={getListPassages} firstDataPassage={firstDataPassage} id='modalDelete' />
    </>
  );
}